.header-section{
    height: 20vh;
    background-color: black;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.previousPage
{
  font-family: 'Chakra Petch';
  height: 100vh;
}

.middle{
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* test */

.gallery{
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: 300px;
    gap: 10px;
    /* overflow-y: scroll; */
    width: 1200px;
    height: 100%;
    
}

.gallery > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    animation: slide 40s linear infinite;
    animation-fill-mode: forwards;
}

.footer-section{
    height: 300px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-btn{
    width: 250px;
    height: 70px;
    border-radius: 0%;
    background-color: rgb(11, 85, 213);
    clip-path: polygon(0 0, 76% 0, 99% 19%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
}

.gallery::-webkit-scrollbar {
    display: none;
  }

  @keyframes slide {
    0% {
      top: 0%;
    }
    100% {
      top: -500%; /* The image width */
    }
  }
  

  .fade{
    background-image: linear-gradient(rgba(0,0,0,100),rgba(0,0,0,0));
    position: absolute !important;
    z-index: 3;
    height:200px;
    width: 100%;
  }
  .fade:not(.show) {
    opacity: 100;
  }

  .fade2{
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,100));
    position: relative !important;
    z-index: 3;
    height:200px;
    width: 100%;
    bottom: 0px;
    position: absolute !important;
  }
  .fade2:not(.show) {
    opacity: 100;
  }

  @media only screen and (max-width: 600px){
    .previousPage
    {
      display: none;
    }
    .galleryControl
    {
      display: none!important;
    }
  }

  .previous-section {
    background-color: #000000;
      display: block;
      margin: 0;
      padding: 90px 0 150px 0;
  }

  .sponsor-section {
    background-color: #000000;
      display: block;
      margin: 0;
      padding: 90px 0 90px 0;
  }