.paySuccess{
  background-color: black;
  height: fit-content;
  color: white;
  font-family: 'Chakra Petch';
}
.message{
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  text-align: center;
  margin-top: 30px;
}

.homelink{
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  margin-top: 40px;
}

.sociallinks{
  display: block;
  text-align: center;
  margin-top: 80px;
}

.pageslink{
  display: block;
  text-align: center;
  margin-top: 30px;
}

.pageslink a{
  padding-left: 20px;
  color: white;
}

.copyrighttext{
  display: block;
  text-align: center;
  margin-top: 25px;
}

.doneImg{
  display: block;
  height: 200px;
  margin-top: 40px;
  background-color: white;
  border-radius: 50%;
  border-color: black;
  margin-left: 44%;
  justify-content: center;
}

@media (max-width: 600px){
  .doneImg{
    margin-left: 33%;
    height: 150px;
  }
  .message{
    font-size: 50px;
  }
}

@media (min-width: 600px) and (max-width: 1100px){
  .doneImg{
    margin-left: 40%;
    height: 170px;
  }
  .message{
    font-size: 60px;
  }
}
