/* html,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:#171717;
  font-family: 'Chakra Petch', sans-serif;
  overflow-y: auto;
} */


.texRaceContainer
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:#171717;
  font-family: 'Chakra Petch', sans-serif;
  overflow-y: auto;
}

.ctr {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%!important;
}

.raw {
  display: flex;
  justify-content: center!important;
}

.key {
  width: 40px;
  height: 40px;
  margin: 4px;
  border: 2px solid #ffffff;
  background: #ffffff44;
}

.large {
  width: 144px;
}

.active1 {
  background: #ffffff99;
}

.interface {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-family: "Bebas Neue", cursive;
}

.time {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%!important;
  color: #ffffff;
  font-size: 6vh;
  background: #00000033!important;
  padding-top: 5px;
  text-align: center!important;
}

.restart {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  color: #ffffff;
  font-size: 80px;
  background: #00000033!important;
  padding-top: 10px;
  pointer-events: auto;
  cursor: pointer;
}

.messagecss
{
  top:5%!important;
  font-size: 30px!important;
  background: none!important;
  cursor: none!important;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 5s;
}

.bg {
  background: linear-gradient(to top, #0a0a0a, #181a1a, #1d1d1f);
}

.notready {
  color: #606060;
}

.clicked {
  pointer-events: none;
  opacity: 0;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 1000, "GRAD" 0, "opsz" 48;
}
.material-symbols-outlined {
  font-size: 1vw;
  cursor: pointer;
}

.levelConsole {
  border: 2px solid black;
  padding: 5px;
}
.optionsContainer{
    display: flex;
    flex-direction: column;
}
.levelvalue:focus
{
  outline: none;
}

.scrolling::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrolling::-webkit-scrollbar-track {
  box-shadow: inset 10 10 5px grey; 
  border-radius: 0px;
}
 
/* Handle */
.scrolling::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 10px;
}

/* Handle on hover */
.scrolling::-webkit-scrollbar-thumb:hover {
  background:#d347fc; 
}
.fade
{
  height: 100vh;
}

.scrolling-black::-webkit-scrollbar-thumb {
  background: #d347fc!important; 
}

.mobile-sm h2 {
  font-size: 24px;
  line-height: 1.5;
  color: #eee;
  letter-spacing: 5px;
}


@media only screen and (max-width: 1024px) {
  .texRaceContainer {
    display: none !important;
  }

  .mobile-sm {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}