.loginPage
{
    height: 90vh;
    width: 100%;
    overflow: hidden;
}

.loginPage a
{
    font-family: 'Chakra Petch';
    text-decoration: none;
    color: rgba(255, 255, 255, 0.79);
}

.loginContainer
{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10vh;
}

.loginLower
{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
/* test */
.user-login-section{
    display: grid;
    grid-template-columns: 45% 55%;
}

.tex{
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.79);
}


.txt-f{
    border: none;
    background:transparent;
    border-bottom: 2px solid silver;
    outline: none;
    color: #FFFFFF;
    border-radius: 0px;
    width: 100%;
}

.lgn-btn{
    width: 162px;
    height: 52px;
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    border: none;
    color: #FFFFFF;
    background: #6C6C6C;
    border-radius: 9px;
}

.txt{

font-family: 'Chakra Petch';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 30px;
text-align: left;
width: 100%;
color: rgba(255, 255, 255, 0.79);
}

img{
    height: 90vh;
}

.imgs{
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}


.lnk{
    width: 100%;
    text-align: right;
}

.lnk a{
    text-decoration-style: none !important;
}

.loginUsername
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginPass
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginForm
{
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

@media screen and (max-width: 600px){

    .loginPage
    {
        height: 85vh;
    }

    .user-login-section{
        height: 85vh;
    }

    .ctn{
        display: none;
    }

    .loginRight
    {
        width: 100vw;
        height: 85vh!important;
    }

    .txt-f
    {
        width: 90%;
    }

    .txt
    {
        width: 90%;
    }

    .lgn-btn
    {
        width: 20vw!important;
    }

    .loginUpper
    {
        line-height: 10vh;
    }

    .registerLink
    {
        font-size: 3vh;
    }

    .tex
    {
        
    font-size: 9vw;

    }
}