.container__content-events::-webkit-scrollbar {
  display: none;
}

.profile__container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
	background-color: #000000;
}

.profile__container::-webkit-scrollbar
{
	width: 6px;
	background-color: #000000;
}

.profile__container::-webkit-scrollbar-thumb
{
	background-color: #ffffff;
}

.profilePage{
  background-color: black;
  overflow: hidden;
  font-family: 'Chakra Petch';
}

.profileSubtext
{
  color: white;
}

.profile__container {
  width: 100%;
  height: 90vh;
  padding: 3em 3em;
  overflow-y: scroll;
  display: flex;
}

.container__content {
  width: 100%;
  flex: 70%;
  padding: 3em;
}

.container__content-id {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.container__img {
  width: 100%;
  flex: 30%;
}
.container__content-id h1 {
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 4.125rem;
  line-height: 121.5%;
  color: #ffffff;
}

.container__content-events {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.container__content-events h2 {
  margin: 0.6em 0;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.5rem;
  color: #ffffff;
}
/* test */
.container__img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 7em;
}

.square {
  height: 60%;
  max-height: 354px;
  aspect-ratio: 1 / 1;
  background-color: grey;
}

@media only screen and (max-width: 600px){

  .container__content
  {
    padding: 0px;
    margin: 2vw;
  }

  .container__content-events
  {
    overflow: unset;
  }

  .profile__container {

    padding: 1em 1em;
    overflow-y: scroll;
  
  }
    .container__img{
      display:none;
    }

  .container__content-id > p {
      font-size: 2vh;
  }

  .container__content-id h1 {
    font-size: 5vh;
  }
}

.profile-heading {
  font-size: 64px;
  font-weight: bold;
}

.profile-subheading {
  font-size: 16px;
}

.profile-events-heading {
  font-size: 38px;
  font-weight: 500;
}