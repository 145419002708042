/* html,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
} */

.route51Container
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:#171717;
    font-family: 'Chakra Petch', sans-serif;
    overflow-y: auto;
}

.ctr
{
    position: absolute;
    bottom:10%;
    left:0;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.raw
{
    display: flex;
    justify-content: center;
}

.key
{
    width: 40px;
    height: 40px;
    margin: 4px;
    border: 2px solid #ffffff;
    background: #ffffff44;
}

.large
{
    width: 144px;
}

.active1
{
    background: #ffffff99;
}
.active
{
    background: none;
}

.interface
{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    font-family: 'Bebas Neue', cursive;
}

.time
{
    position: absolute;
    top:15%;
    left:0;
    width: 20%;
    color:#ffffff;
    font-size: 6vh;
    background:transparent;
    padding-top: 5px;
    text-align: center;
    pointer-events: none;
}

.restart
{
    display: flex;
    justify-content: center;
    position: absolute;
    top:40%;
    left:0;
    width: 100%;
    color: #ffffff;
    font-size: 80px;
    background:transparent;
    padding-top: 10px;
    pointer-events: auto;
    cursor: pointer;
}

.mobile-sm h2 {
    font-size: 24px;
    line-height: 1.5;
    color: #eee;
    letter-spacing: 5px;
}
  
  
  @media only screen and (max-width: 1024px) {
    .route51Container {
      display: none !important;
    }
  
    .mobile-sm {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }
  }
