.intro-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 5s;
  }
  
  .intro-bg {
    background: linear-gradient(to top, #0a0a0a, #181a1a, #1d1d1f);
  }