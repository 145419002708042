.landingContainer {
    background-color: black;
    /* background: linear-gradient(90deg, rgb(18, 41, 44), rgb(0, 0, 0) 50%) 0% 0% / cover no-repeat fixed;; */
    display: flex;
    flex-direction: column;
    height: 100vh!important;
    width: 100%;
}

a{
    text-decoration: none !important;
}

@keyframes float {
    0% {
        transform: translatey(30px);
    }

    50% {
        transform: translatey(0px);
    }

    100% {
        transform: translatey(30px);
    }
}
/* test */
.landingHeadingContainer {
    display: flex;
    margin-left: 4vw;
    /* padding-top: 5vh; */
}

.landingHeadingText {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    font-size: 90px;
    line-height: 110px;
    width: 80vw;
    margin: 40px;
    color: white;
}

.landingSubtextContainer {
    padding: 5vw;
    padding-top: 0px;
    padding-bottom: 3vw;
    width: 100%;
    display: flex;
    justify-content: right;
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
}

.landingSubtext {
    width: 52vw;
}

.t1 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13vw;
    height: 8vh;
    background: linear-gradient(93.98deg, #a1dde4 3.21%, #8da7dd 100.56%);
    border-radius: 0px 20px 0px 20px;
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 32px;
    box-shadow: #6DE9FA;
    text-decoration: none !important;
}

.kmButton :hover {
    cursor: pointer;
}

.kmButton {
    margin-top: 40px;
}

.landingParallax {
    background-image: url("../images/1.svg");
    position: absolute;
    background-position: 0% 15%;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 700px;
    animation: float 3s ease-in-out infinite;
}

.t1 {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #000000;
    background: #feffff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.t1:before {
    content: '';
    background: linear-gradient(45deg,  #74dfcd, #00ffd5, #002bff, #7a00ff, #238e7c, #b8f9ee);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.t1:active {
    color: #ffffff
}

.t1:active:after {
    background: transparent;
}

.t1:hover:before {
    opacity: 1;
}

.t1:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #feffff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@media only screen and (max-width: 600px) 
{
  
    .landingParallax
    {
        display: none;
    }

    .landingContainer
    {
        height: 100vh!important;
        background: black;
        justify-content: center;
    }

    .landingHeadingContainer
    {
        margin-left: 0px;
        justify-content: center;
    }

    .landingHeadingText
    {
        font-size: 7vh;
        margin: 10px;
        text-align: center;
        line-height: 8vh;
    }

    .landingSubtext
    {
        width: 100%;
        text-align: center;
        font-size: 2vh;
    }

    .landingSubtextContainer
    {
        justify-content: center;
    }

    .t1
    {
        text-decoration: none;
    }
    .about-heading, .joining-heading {
        font-size: 42px !important;
        text-align: center !important;
        font-weight: bolder !important;
    }

    .heading-paragraph {
        font-size: 18px !important;
    }

    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .margin-bottom-sm {
        margin-bottom: 0px !important;
    }
    .about-btn {
        display: flex;
        justify-content: center;
    }

}

.landing-section {
    background-color: #000000;
    display: block;
    margin: 0;
    padding: 90px 0 60px 0;
    background-image: linear-gradient(
        to bottom right,
        transparent,
        #00000071
      );
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: hard-light;
    /* background: linear-gradient(90deg, rgb(18, 41, 44), rgb(0, 0, 0) 50%) 0% 0% / cover no-repeat fixed; */
}

.about-heading {
    text-align: left; 
    font-size: 80px; 
    font-weight: bold; 
    letter-spacing: 2px;; 
    line-height: 1.5;
}

.heading-paragraph {
    line-height: 1.5;
    text-align: justify;
    font-size: 24px;
}


