table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }
  
  th,
  td {
    padding: 0.5em;
    text-align: left;
  }
  
  th {
    font-weight: bold;
  }
  
  tr {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  
  td:first-child {
    font-weight: bold;
  }