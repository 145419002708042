.homePage
{
    background-color: black;
    height: 200vh;
}

.aboutPage
{
    background-color: black;
    height: 120vh;
}

.contactPage
{
    background-color: black;
    height: 120vh;
}

.section
{
    background-color: rgb(0, 0, 0);
}

.sectionInput
{
    height: 10vh;
}

textarea, input
{
    font-size: 18px;
    font-family: 'Chakra Petch';
    border-radius: 5px;
    padding: 10px;
}

/* input
{
    text-align: center;
} */

.cmsForm
{
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.sectionText
{
    padding-top: 5vh;
    font-size: 40px;
    color: white;
    font-family: 'Chakra Petch';
}

.sectionContainer
{
    height: 90vh;
    display: flex;
    gap: 3vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactContainer
{
    height: unset;
}

.aboutContainer
{
    height: unset;
}

.submitButton
{
    height: 5vh;
    width: 5vw;
    border-radius: 10px;
}

.submitButton:hover
{
    background-color: rgb(136, 165, 219);
    color: white;
}

.s2CmsForm
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
    width: 100%;
}

.s2Cards
{
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 2vw;
    width: 100%;
}

.s2Card
{
    width: 30vw;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.s2Heading
{
    width: 50vw;
}

.s2Heading
{
    width: 50vw;
}

.submitBtn
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1vh;
}