@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");

.eventAdder__container {
  height: 100vh;
  width: 100%;
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.8s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 20px;
  border: none;

  font-family: "Manrope";
  cursor: pointer;

  color: #fff;
  background: #ee2147;
}

@media only screen and (max-width: 370px) {
  .btn {
    padding: 0.5rem 0.8rem;
    font-size: 12px;
  }
}
